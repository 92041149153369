<template>
	<div>
		<el-form :model="formData" :rules="rules" ref="ruleForm" status-icon label-width="100px" v-loading="loading">
			<el-form-item label="父级菜单" >
				<el-cascader ref="cascader" filterable placeholder="顶级角色" popper-class='cascaders' v-model="formData.pid" :options="data" clearable :props="{value:'id',checkStrictly:true, expandTrigger: 'hover',emitPath:false}">
					<template slot-scope="{ node, data }">
						<div @click="cascaderClick(data)">
							<span>{{ data.label }}</span>
							<span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
						</div>
					</template>
				</el-cascader>
			</el-form-item>
			<el-form-item label="菜单名称" prop="title">
				<el-input type="text" placeholder="请输入菜单名称" v-model="formData.title" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="路由" prop="href">
				<el-input v-model="formData.href" placeholder="请输入菜单路由"></el-input>
			</el-form-item>
			<el-form-item label="类型">
				<el-select v-model="formData.is_menu" placeholder="请选择">
				    <el-option
				      v-for="item in is_menu"
				      :key="item.value"
				      :label="item.label"
				      :value="item.value">
				    </el-option>
				  </el-select>
			</el-form-item>

			<el-form-item label="排序">
				<el-input type="number" v-model="formData.sort"></el-input>
			</el-form-item>
			<el-form-item label="图标">
				<el-input v-model="formData.icon" placeholder="请输入菜单图标"></el-input>
			</el-form-item>
		</el-form>
		<div class="drawer-footer">
			<Button style="margin-right: 8px">重置</Button>
			<Button type="primary" @click="onSubmit('ruleForm')">提交</Button>
		</div>
	</div>
</template>
<script>
import {request} from "@/library/request.js";
import { handleError } from "@vue/runtime-core";
	
export default {
	data () {
		return {
			formData:{
				pid:0,
				title:"",
				href:"",
				is_menu:1,
				sort:0,
				icon:"",
				type:1
			},
			loading:false,
			data:[],
			is_menu:[{
					value:1,
					label:"菜单"
				},
				{
					value:0,
					label:"按钮"
				},
			],
			rules:{
				title:[
					{required: true, message: '请输入菜单名称', trigger: 'blur' }
				],
				href:[
					{required: true, message: '请输入菜单路由', trigger: 'blur' }
				],
			}
		}
	},
	methods:{
		
		formDataPid(value) {
			if(value.length>0){
				this.formData.pid=value[0]
			}else{
				this.formData.pid=0
			}
		},
		onSubmit(formName){
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.save()
				} else {
					return false;
				}
			});
		},
		save(){
			var that = this
			this.loading = true
			let param = new URLSearchParams()
			if(!this.formData.pid){
				param.append('pid', -1)
			}else{
				param.append('pid', this.formData.pid)
			}
			
			param.append('type', this.formData.type)
			param.append('title', this.formData.title)
			param.append('href', this.formData.href)
			param.append('is_menu', this.formData.is_menu)
			param.append('sort', this.formData.sort)
			param.append('icon', this.formData.icon)
			return request({
				url:'system/menu/create',
				method:"post",
				params:param
			}).then(response  => {
				this.loading = false
				if(response.code==200){
					this.$Message.success({
						content:response.msg,
						duration:1.5,
						onClose:function(){	
							that.$emit('routerCancel', true)
						}
					})
				}else{
					this.$Message.error({
						content:response.msg,
						duration: 3
					})
				}
			}).then(err => {
				if(err){
					this.loading = false
					this.$Message.error("系统异常，请稍后重试")
				}
			})
		},
		cascaderClick (nodeData) {
			this.$refs.cascader.checkedValue = nodeData.id;
			this.$refs.cascader.computePresentText();
			this.$refs.cascader.toggleDropDownVisible(false);
		},
	},
	// 强：500
	// 炯：1000
	// 眼：4000
	// 恒：2000
	
	mounted() {
		return request({
			url:'system/menu/list',
			method:"get",
			params:{'selectFields':'id,title,pid,is_menu',searchParams:{'status-=':1}}
		}).then(response  => {
			
			this.data = response.data
			console.log(this.data)
		}).then(err => {
			if(err){
				this.$Message.error("系统异常，请稍后重试")
			}
		})
	},
	watch:{
	},
}
</script>
<style scoped>
	.drawer-footer{
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 10px 16px;
		text-align: right;
		background: #fff;
	}
</style>
<style>
	.cascaders .el-radio__inner {
		top: -18px;
		left: -19px;
		border: 0;
		height: 34px;
		background-color: transparent;
		position: absolute;
	}
	.cascaders .el-radio__input.is-checked .el-radio__inner {
		background: transparent;
	}
</style>